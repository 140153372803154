import { useEffect, useState } from 'react';
import {
  Button,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalBody,
  ModalFooter,
  ModalCloseButton,
  useDisclosure,
  IconButton,
  Icon,
  Textarea,
  Tooltip,
} from '@chakra-ui/react';
import { FiMessageCircle } from 'react-icons/fi';
import { useCreateJobNote, useFetchJobNotes } from '@src/modules/tce/src/context/jobs';

function NoteModal({ imp }) {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [note, setNote] = useState('');
  const { data, isLoading } = useFetchJobNotes(imp.job?.id);
  const { mutateAsync: doCreateNote, isLoading: isCreating } = useCreateJobNote();

  const handleSave = async () => {
    await doCreateNote({ jobId: imp.job?.id, userNotes: note });
    onClose();
  };

  useEffect(() => {
    if (data?.userNotes) {
      setNote(data.userNotes);
    }
  }, [data]);

  if (isLoading) {
    return null;
  }

  return (
    <>
      <Tooltip label="Notes" aria-label="Notes">
        <IconButton
          onClick={onOpen}
          colorScheme="gray"
          variant="ghost"
          size="sm"
          icon={<Icon as={FiMessageCircle} boxSize={5} />}
        />
      </Tooltip>
      <Modal isOpen={isOpen} onClose={onClose} isCentered>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>
            Notes for
            {' '}
            {`${imp.lockYear} #${imp.id}`}
          </ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Textarea
              placeholder="Enter your note..."
              rows="5"
              value={note}
              onChange={(e) => setNote(e.target.value)}
            />
          </ModalBody>
          <ModalFooter>
            <Button onClick={onClose} mr={3} colorScheme="gray">
              Cancel
            </Button>
            <Button onClick={handleSave} isLoading={isCreating}>
              Save Note
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}

export default NoteModal;
