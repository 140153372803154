import {
  Container,
  Heading,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { isEmpty, groupBy } from 'lodash';
import Chart from 'react-apexcharts';
import MainLayout from '@src/components/layouts/main';
import { useReports } from '@src/modules/regional-reporting/context/reports';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import ChartContainer from '@src/modules/onf/components/shared/ChartContainer';
import Pagination from '@src/modules/regional-reporting/components/Pagination';
import EmptyState from '@src/components/shared/EmptyState';
import InfoPane from '@src/modules/regional-reporting/components/InfoPane';
import { reportsList } from '@src/modules/regional-reporting/api/reports-list';

const sortingVehicles = ['Motorcycles', 'Heavy duty trucks and buses', 'Light duty trucks', 'Cars'];
const sortingFuel = ['Petrol', 'Diesel', 'Other'];

export default function GreenhouseGasReport() {
  const theme = useTheme();
  const { data: report, isLoading: isLoadingReport } = useReports('greenhouse-gas-emissions');
  const years = [...new Set(report?.data?.map((x) => x.year))].sort();
  const regionName = report?.rcaName?.replace('Region', '');
  const { description, notification } = reportsList.find((x) => x.url === 'greenhouse-gas-emissions');

  const vehicleType = Object.entries(
    groupBy(report?.data?.filter((x) => x.category === 'Vehicle Type').sort((a, b) => sortingVehicles.indexOf(a.type) - sortingVehicles.indexOf(b.type)), 'type'),
  )
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        x: item.year,
        y: item.value,
      })),
    }));

  const fuelType = Object.entries(groupBy(report?.data?.filter((x) => x.category === 'Fuel Type').sort((a, b) => sortingFuel.indexOf(a.type) - sortingFuel.indexOf(b.type)), 'type'))
    .map(([k, v]) => ({
      name: k,
      data: v.map((item) => ({
        x: item.year,
        y: item.value,
      })),
    }));

  if (isLoadingReport) {
    return (
      <MainLayout>
        <Container maxW="full" display="flex" flexDir="column" minHeight="100%">
          <Pagination currentReportUrl="greenhouse-gas-emissions" reportData={report} isLoading />
          <LoadingSpinner />
        </Container>
      </MainLayout>
    );
  }

  if (isEmpty(report?.data) && report?.rcaName !== null) {
    return (
      <MainLayout>
        <Container maxW="full" display="flex" flexDir="column" minHeight="100%">
          <Pagination currentReportUrl="greenhouse-gas-emissions" reportData={report} isLoading={false} />
          <EmptyState title="No Data" message="Sorry there is no data available for selected region" />
        </Container>
      </MainLayout>
    );
  }

  if (!isEmpty(report?.data)) {
    return (
      <MainLayout>
        <Container maxW="full">
          <Pagination currentReportUrl="greenhouse-gas-emissions" reportData={report} isLoading={false} />
          {description
              && (
                description
              )}
          {notification
              && (
                <InfoPane>
                  <Text>{notification}</Text>
                </InfoPane>
              )}
          <Heading as="h2" mb={4} fontWeight="normal" fontSize="2xl">
            Road transport emissions in
            {' '}
            {regionName}
            {' '}
            - vehicle type
          </Heading>
          <ChartContainer>
            <Chart
              options={
                {
                  chart: {
                    stacked: true,
                    animations: {
                      enabled: false,
                    },
                    zoom: {
                      enabled: false,
                    },
                  },
                  colors: [theme.colors.brand.blue[600], theme.colors.brand.blue[300], theme.colors.green[600], theme.colors.teal[500]],
                  dataLabels: {
                    enabled: false,
                  },
                  tooltip: {
                    enabled: true,
                    shared: true,
                    intersect: false,
                  },
                  xaxis: {
                    categories: years,
                  },
                  yaxis: [
                    {
                      title: {
                        text: 'GHG emissions (kt C02e',
                      },
                      min: 0,
                      tickAmount: 6,
                      labels: {
                        formatter(val) {
                          return val.toFixed(2);
                        },
                      },
                    },
                  ],
                  legend: {
                    onItemClick: {
                      toggleDataSeries: true,
                    },
                  },
                }
              }
              series={vehicleType}
              width="100%"
              height="500"
              type="bar"
            />
          </ChartContainer>
          <Heading as="h2" mt={5} mb={4} fontWeight="normal" fontSize="2xl">
            Road transport emissions in
            {' '}
            {regionName}
            {' '}
            - fuel type
          </Heading>
          <ChartContainer>
            <Chart
              options={
                {
                  chart: {
                    animations: {
                      enabled: false,
                    },
                    zoom: {
                      enabled: false,
                    },
                  },
                  colors: [theme.colors.brand.blue[700], theme.colors.brand.blue[200], theme.colors.brand.orange[500]],
                  dataLabels: {
                    enabled: false,
                  },
                  tooltip: {
                    enabled: true,
                    shared: true,
                    intersect: false,
                  },
                  xaxis: {
                    categories: years,
                  },
                  yaxis: [
                    {
                      title: {
                        text: 'GHG emissions (kt C02e',
                      },
                      min: 0,
                      tickAmount: 6,
                      labels: {
                        formatter(val) {
                          return val.toFixed(2);
                        },
                      },
                    },
                  ],
                  legend: {
                    onItemClick: {
                      toggleDataSeries: true,
                    },
                  },
                  markers: {
                    size: 8,
                  },
                }
              }
              series={fuelType}
              width="100%"
              height="500"
              type="line"
            />
          </ChartContainer>
        </Container>
      </MainLayout>
    );
  }

  // Not a region
  return (
    <MainLayout>
      <Container maxW="full">
        <EmptyState title="Not a Region" message="These reports only return data for regions." />
      </Container>
    </MainLayout>
  );
}
