import {
  Container,
  Heading,
  Text,
  useTheme,
} from '@chakra-ui/react';
import { isEmpty, groupBy } from 'lodash';
import Chart from 'react-apexcharts';
import MainLayout from '@src/components/layouts/main';
import { useReports } from '@src/modules/regional-reporting/context/reports';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import ChartContainer from '@src/modules/onf/components/shared/ChartContainer';
import Pagination from '@src/modules/regional-reporting/components/Pagination';
import EmptyState from '@src/components/shared/EmptyState';
import InfoPane from '@src/modules/regional-reporting/components/InfoPane';
import { reportsList } from '@src/modules/regional-reporting/api/reports-list';

export default function SmoothTravel() {
  const theme = useTheme();
  const { data: report, isLoading: isLoadingReport } = useReports('smooth-travel');
  const years = [...new Set(report?.data?.map((x) => x.latestYear))].sort();
  const regionName = report?.rcaName?.replace('Region', '');
  const { description, notification } = reportsList.find((x) => x.url === 'smooth-travel');

  const localRoads = Object.entries(groupBy(report?.data?.filter((item) => item.graph === 'Local'), 'category'))
    .map(([k, v]) => {
      const interpolatedData = years.map((year) => {
        const matchingValue = v.find((item) => item.latestYear === year);
        if (matchingValue) {
          return { x: year, y: matchingValue.percentage.toFixed(2) };
        }
        return { x: year, y: 0 };
      });
      return {
        name: k,
        data: interpolatedData,
        color: theme.colors.onfCategory[k].base,
      };
    });

  const stateHighways = Object.entries(groupBy(report?.data?.filter((item) => item.graph === 'State Highways'), 'category'))
    .map(([k, v]) => {
      const interpolatedData = years.map((year) => {
        const matchingValue = v.find((item) => item.latestYear === year);
        if (matchingValue) {
          return { x: year, y: matchingValue.percentage.toFixed(2) };
        }
        return { x: year, y: 0 };
      });
      return {
        name: k,
        data: interpolatedData,
        color: theme.colors.onfCategory[k].base,
      };
    });

  if (isLoadingReport) {
    return (
      <MainLayout>
        <Container maxW="full" display="flex" flexDir="column" minHeight="100%">
          <Pagination currentReportUrl="smooth-travel" reportData={report} isLoading />
          <LoadingSpinner />
        </Container>
      </MainLayout>
    );
  }

  if (isEmpty(report?.data) && report?.rcaName !== null) {
    return (
      <MainLayout>
        <Container maxW="full" display="flex" flexDir="column" minHeight="100%">
          <Pagination currentReportUrl="smooth-travel" reportData={report} isLoading={false} />
          <EmptyState title="No Data" message="Sorry there is no data available for selected region" />
        </Container>
      </MainLayout>
    );
  }

  if (!isEmpty(report?.data) && report?.rcaName !== null) {
    return (
      <MainLayout>
        <Container maxW="full">
          <Pagination currentReportUrl="smooth-travel" reportData={report} isLoading={false} />
          {description
              && (
                description
              )}
          {notification
              && (
                <InfoPane>
                  <Text>{notification}</Text>
                </InfoPane>
              )}
          <Heading as="h2" mb={4} fontWeight="normal" fontSize="2xl">
            Travel on &apos;smooth&apos; state highways in
            {' '}
            {regionName}
          </Heading>
          <ChartContainer>
            <Chart
              options={
              {
                chart: {
                  animations: {
                    enabled: false,
                  },
                  zoom: {
                    enabled: false,
                  },
                },
                dataLabels: {
                  enabled: false,
                },
                tooltip: {
                  enabled: true,
                  shared: true,
                },
                xaxis: {
                  categories: years,
                },
                yaxis: [
                  {
                    title: {
                      text: 'Travel on smooth state highways (%)',
                    },
                    labels: {
                      formatter(val) {
                        return `${val.toFixed(0)}%`;
                      },
                    },
                  },
                ],
                legend: {
                  onItemClick: {
                    toggleDataSeries: false,
                  },
                },
                markers: {
                  size: 8,
                },
              }
            }
              series={stateHighways}
              width="100%"
              height="500"
              type="line"
            />
          </ChartContainer>
          <Heading as="h2" mt={6} mb={4} fontWeight="normal" fontSize="2xl">
            Travel on &apos;smooth&apos; local roads in
            {' '}
            {regionName}
          </Heading>
          <ChartContainer>
            <Chart
              options={
                {
                  chart: {
                    animations: {
                      enabled: false,
                    },
                    zoom: {
                      enabled: false,
                    },
                  },
                  dataLabels: {
                    enabled: false,
                  },
                  tooltip: {
                    enabled: true,
                    shared: true,
                  },
                  xaxis: {
                    categories: years,
                  },
                  yaxis: [
                    {
                      title: {
                        text: 'Travel on smooth local roads (%)',
                      },
                      labels: {
                        formatter(val) {
                          return `${val.toFixed(0)}%`;
                        },
                      },
                    },
                  ],
                  legend: {
                    onItemClick: {
                      toggleDataSeries: false,
                    },
                  },
                  markers: {
                    size: 8,
                  },
                }
              }
              series={localRoads}
              width="100%"
              height="500"
              type="line"
            />
          </ChartContainer>
        </Container>
      </MainLayout>
    );
  }

  // Not a region
  return (
    <MainLayout>
      <Container maxW="full">
        <EmptyState title="Not a Region" message="These reports only return data for regions." />
      </Container>
    </MainLayout>
  );
}
