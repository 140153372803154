import {
  Text,
  Link,
  UnorderedList,
  ListItem,
} from '@chakra-ui/react';

export const reportsList = [
  {
    url: 'safety',
    name: 'Safety',
    description:
  <>
    <Text mb={4}>
      The Road to Zero strategy sets out our vision for a New Zealand where no one is killed or seriously injured in road crashes. The direct costs for everyone who was injured on New Zealand roads in 2018/19 amounted to just over $1 billion. While we cannot prevent crashes from occurring, the reality is that we can control whether the people affected walk away without death or serious injury. This is why we continue to invest in road safety outcomes.
    </Text>
    <Text>
      Road to Zero envisions a transport system where all users are able to get where they are going safely, regardless of whether they are walking, cycling, driving, motorcycling, or taking public transport.
    </Text>
  </>,
    moreInfo:
  <>
    <Text mb={4}>
      Data on death and serious injury is sourced from the NZTA open data portal -
      {' '}
      <Link href="https://opendata-nzta.opendata.arcgis.com/datasets/crash-analysis-system-cas-data-1/explore" isExternal>Crash Analysis System</Link>
      {' '}
      (CAS) data. This data is updated at least monthly.
    </Text>
    <Text mb={4}>
      Data on vehicle kilometres travelled in each region is published by the Ministry of Transport and is by calendar year. Therefore, updated data is currently only available in January for the reporting year 1 July-30 June.
    </Text>
    <Text mb={4}>
      The
      {' '}
      <Link href="https://www.transport.govt.nz/statistics-and-insights/road-transport/sheet/vehicle-kms-travelled-vkt" isExternal>data is sourced</Link>
      {' '}
      from Warrant of Fitness (WoF) and Certificate of Fitness (CoF) odometer readings.
    </Text>
    <Text mb={4}>
      Regional breakouts of the WoF and CoF odometer data shown here is based on the region where the vehicle was inspected, not the region where the kilometres were actually driven.
    </Text>
    <Text>Regional Councils can use the data and graphs in this page to report consistently on performance measures for regions by using images of the graphs (downloadable from the icon at the top right of each graph). More dynamic reporting can be used by downloading the data.</Text>
  </>,
    links: [
      {
        url: 'https://www.ecan.govt.nz/your-region/living-here/transport/regional-transport-planning/road-safety/',
        title: 'Road safety in Canterbury',
        isExternal: true,
      },
    ],
    notification: 'Please send feedback to Road Efficiency Group Te Ringa Maimoa Support in regards to Measure standards or display.',
    enabled: true,
  },
  {
    url: 'smooth-travel',
    name: 'Smooth Travel',
    description:
  <Text>The data shows the percentage of travel (VKTs) for each year of the reporting period.  This is a quantitative measure of the road surface and pavement quality experienced by road users.  Smooth Travel exposure data is sourced from each Road Controlling Authorities RAMM database through Road Efficiency Group Te Ringa Maimoa Transport Insights.</Text>,
    moreInfo:
  <Text>Regional Councils can use the data and graphs in this page to report consistently on performance measures for regions by using images of the graphs (downloadable from the icon at the top right of each graph). More dynamic reporting can be used by downloading the data.</Text>,
    links: [
      {
        url: 'https://www.ecan.govt.nz/your-region/living-here/transport/regional-transport-planning/transport-network-maintenance/',
        title: 'Transport Network Maintenance',
        isExternal: true,
      },
    ],
    notification: 'Please send feedback to Road Efficiency Group Te Ringa Maimoa Support in regards to Measure standards or display.',
    enabled: true,
  },
  {
    url: 'access-to-jobs',
    name: 'Access to Jobs',
    description:
  <>
    <Text mb={4}>Access to Jobs charts show the proportion of the region&apos;s jobs that can be accessed by the average resident within a given mode and travel time threshold. </Text>
    <Text mb={4}>
      This measure is a function of two things; where employment is located relative to where people live, and how well the transport system enables people to travel to access their place of employment.
    </Text>
    <Text mb={4}>
      Each year NZTA provides results that describe access to employment by a range of modes. This analysis measures the proportion of each region&apos;s jobs that can be accessed within 30 minutes drive, or 45 minutes walk, cycle, or Public Transport.
    </Text>
    <Text mb={4}>
      To calculate the results for this measure, a whole-of-network analysis is used integrating multiple sources (General Transit Feed Specification or Gtfs files for Public Transport, Open Street Maps for active-modes, and TomTom networked travel-times for driving).
    </Text>
    <Text mb={4}>
      Travel time simulation is based upon an 8am departure during a non-holiday weekday in early March each year.  Access to economic opportunities is the percentage of the region&apos;s jobs that can be reached within 45 minutes during morning peak on a non-holiday weekday in early March each year.
    </Text>
    <Text>
      The employment data used is from Stats NZ&apos;s business demographic dataset, which is an annual count of employees and excludes the self-employed/working proprietor.
    </Text>
  </>,
    moreInfo:
  <>
    <Text mb={4}>
      The data presented is the average level of access to employment across all Statistical Area Units (SA1s) in the region, including the most and the least accessible. It is a highly aggregated measure.
    </Text>
    <Text mb={4}>
      Geographically smaller or more highly urbanised or centralised regions tend to have greater levels of average access to employment. The more geographically large, less centralised and/or less urbanised a region is the lesser its access to employment tends to be.
    </Text>
    <Text mb={4}>
      For example; if Singapore was represented here it would likely score particularly well, because it is geographically very small and high-density with excellent access by all modes.  Whereas Johannesburg-Pretoria would likely score particularly poorly, being effectively two cities approximately an hour’s drive from each other (and therefore outside of our threshold) but located within the same region (and also having a high share of employment located in peripheral business areas outside of their city centres).
    </Text>
    <Text>Regional Councils can use the data and graphs in this page to report consistently on performance measures for regions by using images of the graphs (downloadable from the icon at the top right of each graph). More dynamic reporting can be used by downloading the data.</Text>
  </>,
    links: [
    ],
    notification: 'Please send feedback to Road Efficiency Group Te Ringa Maimoa Support in regards to Measure standards or display.',
    enabled: true,
  },
  {
    url: 'greenhouse-gas-emissions',
    name: 'Greenhouse Gas Emissions',
    description:
  <>
    <Text mb={4}>
      Transport emissions are the fastest growing source of greenhouse gas emissions in New Zealand.  Reducing transport emissions is critical to reducing the effects of climate change, and ensuring New Zealand can meet its targets under the
      {' '}
      <Link href="https://environment.govt.nz/what-government-is-doing/international-action/about-the-paris-agreement/" isExternal>Paris Agreement</Link>
      {' '}
      and the
      {' '}
      <Link href="https://environment.govt.nz/acts-and-regulations/acts/climate-change-response-amendment-act-2019/" isExternal>Climate Change Response (Zero Carbon) Amendment Act.</Link>
    </Text>
    <Text>
      Land transport is a major contributor to greenhouse gas emissions.  The majority of all road transport CO
      <sub>2</sub>
      {' '}
      emissions are from light vehicle use (cars, SUVs, Utes, vans and light trucks) as opposed to heavy vehicles.
    </Text>
  </>,
    moreInfo:
  <>
    <Text mb={4}>
      Stats NZ custom data, licensed for re-use under the Creative Commons Attribution 4.0 International licence.
    </Text>
    <Text mb={4}>
      This series is modelled data, based off the national emissions inventory. The reporting period is by calendar year. New data becomes available annually in September for the previous reporting year (nine months after the end of the reporting period). Statistics New Zealand regularly revise their historical back data.
    </Text>
    <Text mb={4}>
      The data is direct emissions only, indirect emissions are excluded. Emissions are expressed in kilo tonnes of carbon dioxide equivalents (kt CO
      <sub>2</sub>
      e) which are emissions weighted by their 100-year global warming potential.
    </Text>
    <Text mb={4}>
      ‘Light duty trucks’ here are light commercial vehicles under 3.5 tonnes. This includes goods vans, trucks and utility vehicles weighing up to 3.5 tonnes. It excludes passenger cars and vans, including sports utility vehicles (SUVs), which fall into the first category: light passenger vehicles or ‘Cars’.
    </Text>
    <Text mb={4}>
      Further information on the methodology can be found at:
      {' '}
      <Link href="https://www.stats.govt.nz/methods/about-regional-greenhouse-gas-emissions-statistics" isExternal>Stats NZ</Link>
    </Text>
    <Text>Regional Councils can use the data and graphs in this page to report consistently on performance measures for regions by using images of the graphs (downloadable from the icon at the top right of each graph). More dynamic reporting can be used by downloading the data.</Text>
  </>,
    links: [
      {
        url: 'https://www.ecan.govt.nz/your-region/living-here/transport/regional-transport-planning/transport-emissions/',
        title: 'Transport Emissions',
        isExternal: true,
      },
      {
        url: 'https://environment.govt.nz/what-government-is-doing/international-action/about-the-paris-agreement/',
        title: 'Paris Agreement',
        isExternal: true,
      },
      {
        url: 'https://environment.govt.nz/acts-and-regulations/acts/climate-change-response-amendment-act-2019/',
        title: 'Climate Change Response (Zero Carbon) Amendment Act 2019',
        isExternal: true,
      },
      {
        url: 'https://www.stats.govt.nz/methods/about-regional-greenhouse-gas-emissions-statistics',
        title: 'About regional greenhouse gas emissions statistics | Stats NZ',
        isExternal: true,
      },
    ],
    notification: 'Please send feedback to Road Efficiency Group Te Ringa Maimoa Support in regards to Measure standards or display.',
    enabled: true,
  },
  {
    url: 'freight',
    name: 'Freight',
    description:
  <>
    <Text mb={4}>
      The movement of goods to, from and around New Zealand is essential for our society and economy to function and ﬂourish and impacts our quality of life.
    </Text>
    <Text mb={4}>
      Heavy vehicles represent around 6 per cent of total vehicle kilometres travelled on our roads, but approximately 20-25 per cent of total road transport emissions. GHG emissions from freight in New Zealand is closely correlated with the amount of diesel used by trucks.
    </Text>
    <Text mb={4}>
      Decarbonising freight movement, and ensuring the efficient movement of freight, including realising opportunities for freight mode shift to rail and coastal shipping (where appropriate), is critical to achieving our emission reduction targets, but also has many potential broader benefits for New Zealand.
    </Text>
    <Text mb={4}>
      These include potential cost savings for freight operators (reduced labour, fuel costs), reduced road maintenance costs for councils, greater redundancy in local and national supply chains and more resilience to disruption to name just a few.
    </Text>
    <Text mb={4}>
      The key types of freight are:
    </Text>
    <UnorderedList mb={4}>
      <ListItem>
        Land/Road - Most of the freight within New Zealand is transported via road, whether that be milk tankers, logging trucks, concrete trucks, or the postal delivery van.
      </ListItem>
      <ListItem>
        Air Freight - Air freight carries less than one percent of New Zealand’s international trade by volume, but about 16 percent of exports and 22 percent of imports by dollar value. Air transport is critical for the transport of high-value and/or time-sensitive goods, such as flowers, seafood, and urgent medical supplies.
      </ListItem>
      <ListItem>
        Sea - Approximately 99 percent of New Zealand’s trade by volume, and 80 percent by value is carried by sea. The majority of freight movement is not between regions, but to and from the site of primary production or processing facility and the nearest seaport.
      </ListItem>
      <ListItem>
        Rail – is mostly used for moving containerised freight and bulk commodities, such as logs and coal. Rail is ideally suited to the movement of these types of high-volume, low-value commodities.
      </ListItem>
    </UnorderedList>
    <Text>At this time, only the reporting of rail is in scope.</Text>
  </>,
    moreInfo:
  <>
    <Text mb={4}>
      Rail data is sourced from the
      {' '}
      <Link href="https://www.transport.govt.nz/statistics-and-insights/freight-and-logistics/figs-rail/" isExternal>Ministry of Transport Freight Information Gathering System</Link>
      {' '}
      (FIGS).
    </Text>
    <Text mb={4}>
      Data is published by MoT quarterly and reported here annually, for the reporting period 1 July – 30 June.
    </Text>
    <Text mb={4}>
      Rail figures are total tonne-kilometres of rail freight moved both to/from the selected region and all other NZ regions, and within the selected region.
    </Text>
    <Text mb={4}>
      Not all NZ regions have access to rail
    </Text>
    <Text>Regional Councils can use the data and graphs in this page to report consistently on performance measures for regions by using images of the graphs (downloadable from the icon at the top right of each graph). More dynamic reporting can be used by downloading the data.</Text>
  </>,
    links: [
      {
        url: 'https://www.transport.govt.nz/statistics-and-insights/freight-and-logistics/trade-trends/',
        title: 'Freight Information Gathering System (FIGS) Overview',
        isExternal: true,
      },
      {
        url: 'https://www.transport.govt.nz/statistics-and-insights/freight-and-logistics/figs-rail/',
        title: 'FIGS Rail',
        isExternal: true,
      },
    ],
    notification: 'Please send feedback to Road Efficiency Group Te Ringa Maimoa Support in regards to Measure standards or display.',
    enabled: true,
  },
  {
    url: 'active-travel',
    name: 'Active Travel',
    description:
  <>
    <Text mb={4}>
      Active travel is becoming a more popular mode of travel for more New Zealanders. This not only benefits individual physical health and wellbeing, but it also benefits mental wellbeing, society and the environment too.  The rise of micro-mobility, such as electric bikes and electric scooters, are making active travel more accessible and appealing to more people, and as a result, both central and local government are investing more into dedicated safe walking and cycling facilities.
    </Text>
    <Text>
      Travel for work and education are trips that people usually make frequently. If we can successfully shift these trips to modes other than private vehicle then the individuals concerned are more likely to consider walking, cycling, or taking public transport for other trip purposes too. This data shows the share of total travel for work and education that was undertaken by walking and cycling combined at the last census.
    </Text>
  </>,
    moreInfo:
  <>
    <Text mb={4}>
      <Link href="https://nzdotstat.stats.govt.nz/wbos/Index.aspx" isExternal>Source data</Link>
      {' '}
      is from the New Zealand 2018 census responses for the topic ‘main means of travel to work and education’.
    </Text>
    <Text mb={4}>
      Responses are based on the respondent’s main means of travel, the transport mode they most commonly use for that trip type. This question in the 2018 census did not differentiate between walking, cycling and other forms of micro mobility, such as electric scooters. Not all respondents state which territorial authority area they reside in.
    </Text>
    <Text mb={4}>
      Updated data is only available with each New Zealand census, which is undertaken every five years. Accordingly, this is not an annual measure. The New Zealand census is usually undertaken on a Tuesday in the month of March.
    </Text>
    <Text mb={4}>
      This data will be updated with information from the New Zealand 2023 census, once available.
    </Text>
    <Text>Regional Councils can use the data and graphs in this page to report consistently on performance measures for regions by using images of the graphs (downloadable from the icon at the top right of each graph). More dynamic reporting can be used by downloading the data.</Text>
  </>,
    links: [
    ],
    notification:
  <>
    <Text>
      The Active Travel results still use the 2018 census data and will be refreshed with the 2023 census data once it is available.
    </Text>
    <Text>
      As per the
      {' '}
      <Link href="https://www.stats.govt.nz/2023-census/2023-census-release-schedule/" isExternal>census 2023 release schedule</Link>
      {' '}
      the active travel summaries will be released in June 2025.
    </Text>
    <Text>
      Please send feedback to Road Efficiency Group Te Ringa Maimoa Support in regards to Measure standards or display.
    </Text>
  </>,
    enabled: true,
  },
  {
    url: 'forecast-maintenance-spend',
    name: 'Forecast Maintenance and Renewals Spend',
    description:
  <>
    <Text mb={4}>Maintenance and renewal of the existing transport network, and additional investment to respond to changes in how roads are being used, is critical to sustaining the region&apos;s prosperity.</Text>
    <Text mb={4}>One of the aims of the Regional Transport Committee (RTC) is to ensure a sustainable approach to funding and maintaining our existing networks.  For every dollar spent by local councils on road maintenance and renewals, central government through NZTA matches that based on a funding assistance rate. This funding comes primarily from road user charges and fuel taxes.</Text>
    <Text>The shift to a low emissions transport system will result in less income from these sources.  It&apos;s important that the funding system is reviewed and improved.</Text>
  </>,
    moreInfo:
  <Text>Regional Councils can use the data and graphs in this page to report consistently on performance measures for regions by using images of the graphs (downloadable from the icon at the top right of each graph). More dynamic reporting can be used by downloading the data.</Text>,
    links: [
      {
        url: 'https://haveyoursay.ecan.govt.nz/regional-land-transport-plan',
        title: 'Regional Land Transport Plan 2021-31',
        isExternal: true,
      },
    ],
    notification: 'Please send feedback to Road Efficiency Group Te Ringa Maimoa Support in regards to Measure standards or display.',
    enabled: true,
  },
  {
    url: 'resilience',
    name: 'Resilience',
    description:
  <>
    <Text mb={4}>Regions experience natural hazards that include flooding, surface flooding and coastal inundation and damage from slips and dropouts. These events can be particularly problematic in a region which has a heavy reliance on bridges for river crossings on critical freight and visitor routes. The changing climate is expected to increase network vulnerability, with increased exposure to risks associated with sea level rise, coastal erosion and storm surges.</Text>
    <Text>These risks place pressure on our transport links and have the potential to isolate districts or communities. In many instances alternative routes that must be used are indirect, resulting in extremely long detours, or are unsuitable for certain vehicles (such as high-productivity motor vehicles).</Text>
  </>,
    moreInfo:
  <Text>Regional Councils can use the data and graphs in this page to report consistently on performance measures for regions by using images of the graphs (downloadable from the icon at the top right of each graph). More dynamic reporting can be used by downloading the data.</Text>,
    links: [
      {
        url: 'https://www.nzta.govt.nz/roads-and-rail/highways-information-portal/technical-disciplines/resilience/resilience-planning-tools/',
        title: 'Resilience planning tools',
        isExternal: true,
      },
    ],
    notification: 'Please send feedback to Road Efficiency Group Te Ringa Maimoa Support in regards to Measure standards or display.<br /><br />The State Highways data is indicative of the known weather related road closures that occurred during each reporting year.<br /><br />Note: The local road closure data collection initative commenced for the year 2022/23.  There is no 2021/22 data available.  The intent of this graph is to encourage RCAs to begin capturing local road closure data for display purposes.',
    enabled: true,
  },
];
