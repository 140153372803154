import {
  Flex,
  HStack,
  IconButton,
  Portal,
  Spinner,
  Text,
  Tooltip,
} from '@chakra-ui/react';
import html2pdf from 'html2pdf.js';
import { useState } from 'react';
import { FiDownload } from 'react-icons/fi';
import { useRecoilValue } from 'recoil';
import { currentDatabaseSelector } from '@transport-insights/uikit';

function PrintablePdf({
  printableRef, pageTitle = null, fileName = null, printableStyles = null,
}) {
  const [isGeneratingPdf, setIsGeneratingPdf] = useState(false);
  const currentDatabase = useRecoilValue(currentDatabaseSelector);
  const handlePrint = async () => {
    setIsGeneratingPdf(true);
    // Clone the content of the reference
    const printableContent = printableRef.current.cloneNode(true);

    // Replace each canvas element with an image version
    const originalCanvases = printableRef.current?.querySelectorAll('canvas');
    const clonedCanvases = printableContent?.querySelectorAll('canvas');

    // Set the scale factor based on the device pixel ratio
    const widthScaleFactor = window.devicePixelRatio > 1 ? 0.55 : 0.75;
    const heightScaleFactor = window.devicePixelRatio > 1 ? 0.33 : 0.65;

    originalCanvases?.forEach((canvas, indx) => {
      const initialWidth = canvas.width;
      const initialHeight = canvas.height;
      const img = document.createElement('img');
      img.src = canvas.toDataURL('image/png');

      // Check if the canvas is wider than it is tall
      if (initialWidth > initialHeight) {
        img.style.maxWidth = `${initialWidth * widthScaleFactor}px`;
        img.style.maxHeight = 'none'; // No need to constrain height
        img.style.height = 'auto';
      } else {
        img.style.maxHeight = `${initialHeight * heightScaleFactor}px`;
        img.style.maxWidth = 'none'; // No need to constrain width
        img.style.width = 'auto';
      }

      const clonedCanvas = clonedCanvases[indx];
      if (clonedCanvas && clonedCanvas.parentNode) {
        clonedCanvas.parentNode.replaceChild(img, clonedCanvas);
      }
    });

    // Extract styles, replacing CSS variables if necessary
    let styles = '';
    Array.from(document.styleSheets).forEach((styleSheet) => {
      try {
        Array.from(styleSheet.cssRules).forEach((rule) => {
          styles += rule.cssText;
        });
      } catch (e) {
        console.warn('Could not access stylesheet:', styleSheet.href);
      }
    });

    // Set up the HTML content with styles
    const finalHtml = `
      <html>
        <head>
          <title>Print</title>
          <style>
            ${styles}
            body, html {
              font-family: Arial, sans-serif;
              width: 100%;
            }
            img {
              height: unset;
              display: inline-block !important;
              max-width: 100%;
            }
            .pdf-container {
              margin: auto;
              padding: 5mm;
              overflow: hidden;
            }
            .pdf-container .hidden-print { display: none!important; }
            .pdf-container .lockyear-badge {
              font-size: 8px;
            }
            .pdf-container .quarter-badge {
              font-size: 8px;
            }
            .pdf-container .report-wrapper {
              border: none;
              margin: 0px;
              padding: 0px;
              width: 100%;
            }
            .pdf-container .report-header {
              top: 0px;
              padding: 4px 16px!important;
              background: #f1f1f1;
              border-radius: 4px;
              border-bottom: none;
            }
            .pdf-container .report-body {
              padding: 10px 0px 0px 0px;
              width: 100%;
            }
            .pdf-container .pdf-half {
              width: 45%;
            }
            .pdf-container .report-section-header {
              font-size: 13px;
              margin: 0px  0px 10px 0px;
            }
            .pdf-container .chart-title {
              font-size: 9px;
              margin-bottom: 3mm;
              line-height: 0;
            }
            ${printableStyles}
          </style>
        </head>
        <body>
          <div class="pdf-container">
            <header style="display: flex; align-items: center; justify-content: space-between; width: 100%; padding-bottom: 3mm; ">
              <h2 style="margin: 0px; line-height: 0.5; font-size: 14px; padding: 0px;">${pageTitle ? `${pageTitle} - ${currentDatabase?.name}` : currentDatabase?.name}</h2>
              <img src="/images/reg-logo-print.jpg" width="80px" height="auto" />
            </header>
            <div>
              ${printableContent.innerHTML}
            </div>
          </div>
        </body>
      </html>
    `;

    // PDF options
    const options = {
      margin: 0,
      image: { type: 'jpeg', quality: 0.98 },
      html2canvas: {
        scale: 4, width: 1123,
      },
      jsPDF: { unit: 'mm', format: 'a4', orientation: 'landscape' },
    };

    // Generate and save PDF
    await html2pdf().set(options)
      .from(finalHtml)
      .toContainer()
      .toPdf()
      .get('pdf')
      .then((pdf) => {
        pdf.save(`${fileName} - ${currentDatabase?.name}.pdf`);
      });
    setIsGeneratingPdf(false);
  };

  return (
    <>
      {isGeneratingPdf && (
      <Portal>
        <Flex
          zIndex={9999}
          position="fixed"
          top={0}
          bottom={0}
          left="0"
          right={0}
          width="100vw"
          height="100vh"
          background="whiteAlpha.800"
          align="center"
          justify="center"
          backdropFilter="blur(4px)"
          marginInlineStart="0!important"
        >
          <HStack p="0" m="0">
            <Spinner color="brand.orange.500" size="lg" />
            <Text>Generating PDF...</Text>
          </HStack>
        </Flex>
      </Portal>
      )}
      <Tooltip
        label="Save as PDF"
        placement="left"
        display={isGeneratingPdf ? 'none' : 'inherit'}
      >
        <IconButton
          icon={<FiDownload />}
          borderRadius="full"
          colorScheme="gray"
          ml={3}
          onClick={handlePrint}
        />
      </Tooltip>
    </>
  );
}

export default PrintablePdf;
