import {
  Container,
  Heading,
  SimpleGrid,
  Box,
  useTheme,
  Text,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import Chart from 'react-apexcharts';
import MainLayout from '@src/components/layouts/main';
import { useReports } from '@src/modules/regional-reporting/context/reports';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import ChartContainer from '@src/modules/onf/components/shared/ChartContainer';
import Pagination from '@src/modules/regional-reporting/components/Pagination';
import EmptyState from '@src/components/shared/EmptyState';
import InfoPane from '@src/modules/regional-reporting/components/InfoPane';
import { reportsList } from '@src/modules/regional-reporting/api/reports-list';

export default function AccessToJobs() {
  const theme = useTheme();
  const { data: report, isLoading: isLoadingReport } = useReports('access-to-jobs');
  const { description, notification } = reportsList.find((x) => x.url === 'access-to-jobs');

  const splitRegionName = (name) => {
    if (!name.includes('/')) return name;
    const nameArray = name.split('/').join('/');
    const half = Math.ceil(nameArray.length / 2);
    return [nameArray.slice(0, half), nameArray.slice(-half)];
  };

  const walkData = report?.data?.map(({ id, regionName, walk }) => (
    { x: splitRegionName(regionName), y: walk, fillColor: id === report.rcaId ? theme.colors.brand.orange[500] : theme.colors.brand.blue[700] }
  )).sort((a, b) => parseFloat(b.y) - parseFloat(a.y));

  const cycleData = report?.data?.map(({ id, regionName, cycle }) => (
    { x: splitRegionName(regionName), y: cycle, fillColor: id === report.rcaId ? theme.colors.brand.orange[500] : theme.colors.brand.blue[700] }
  )).sort((a, b) => parseFloat(b.y) - parseFloat(a.y));

  const ptData = report?.data?.map(({ id, regionName, publicTransport }) => (
    { x: splitRegionName(regionName), y: publicTransport, fillColor: id === report.rcaId ? theme.colors.brand.orange[500] : theme.colors.brand.blue[700] }
  )).sort((a, b) => parseFloat(b.y) - parseFloat(a.y));

  const driveData = report?.data?.map(({ id, regionName, drive }) => (
    { x: splitRegionName(regionName), y: drive, fillColor: id === report.rcaId ? theme.colors.brand.orange[500] : theme.colors.brand.blue[700] }
  )).sort((a, b) => parseFloat(b.y) - parseFloat(a.y));

  if (isLoadingReport) {
    return (
      <MainLayout>
        <Container maxW="full" display="flex" flexDir="column" minHeight="100%">
          <Pagination currentReportUrl="access-to-jobs" reportData={report} isLoading />
          <LoadingSpinner />
        </Container>
      </MainLayout>
    );
  }

  if (isEmpty(report?.data) && report?.rcaName !== null) {
    return (
      <MainLayout>
        <Container maxW="full" display="flex" flexDir="column" minHeight="100%">
          <Pagination currentReportUrl="access-to-jobs" reportData={report} isLoading={false} />
          <EmptyState title="No Data" message="Sorry there is no data available for selected region" />
        </Container>
      </MainLayout>
    );
  }

  if (!isEmpty(report?.data) && report?.rcaName !== null) {
    return (
      <MainLayout>
        <Container maxW="full">
          <Pagination currentReportUrl="access-to-jobs" reportData={report} isLoading={false} />
          {description
              && (
                description
              )}
          {notification
              && (
                <InfoPane>
                  <Text>{notification}</Text>
                </InfoPane>
              )}
          <SimpleGrid
            columns={{
              base: 2, md: 2, lg: 2, xl: 4,
            }}
            spacing={4}
            mt={4}
          >
            <Box>
              <Heading as="h2" mb={4} fontWeight="normal" fontSize="2xl">
                Walk
              </Heading>
              <ChartContainer>
                <Chart
                  options={
                    {
                      chart: {
                        animations: {
                          enabled: false,
                        },
                        zoom: {
                          enabled: false,
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      tooltip: {
                        enabled: true,
                        shared: false,
                        y: {
                          show: true,
                          formatter(val) {
                            return `${val.toFixed(0)}%`;
                          },
                        },
                      },
                      yaxis: {
                        labels: {
                          offsetX: 0,
                          offsetY: 3,
                          style: {
                            fontSize: '10px',
                          },
                        },
                      },
                      xaxis: {
                        title: {
                          text: '% Access to Jobs',
                        },
                        tickAmount: 4,
                        forceNiceScale: true,
                        labels: {
                          formatter(val) {
                            return `${val.toFixed(0)}%`;
                          },
                        },
                      },
                      legend: {
                        onItemClick: {
                          toggleDataSeries: false,
                        },
                      },
                      markers: {
                        size: 8,
                      },
                      plotOptions: {
                        bar: {
                          horizontal: true,
                        },
                      },
                    }
                  }
                  series={[{
                    name: 'Walk',
                    data: walkData,
                  }]}
                  width="100%"
                  height="500"
                  type="bar"
                />
              </ChartContainer>
            </Box>
            <Box>
              <Heading as="h2" mb={4} fontWeight="normal" fontSize="2xl">
                Cycle
              </Heading>
              <ChartContainer>
                <Chart
                  options={
                    {
                      chart: {
                        animations: {
                          enabled: false,
                        },
                        zoom: {
                          enabled: false,
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      tooltip: {
                        enabled: true,
                        shared: false,
                        y: {
                          show: true,
                          formatter(val) {
                            return `${val.toFixed(0)}%`;
                          },
                        },
                      },
                      yaxis: {
                        labels: {
                          offsetX: 0,
                          offsetY: 3,
                          style: {
                            fontSize: '10px',
                          },
                        },
                      },
                      xaxis: {
                        title: {
                          text: '% Access to Jobs',
                        },
                        tickAmount: 4,
                        forceNiceScale: true,
                        labels: {
                          formatter(val) {
                            return `${val.toFixed(0)}%`;
                          },
                        },
                      },
                      legend: {
                        onItemClick: {
                          toggleDataSeries: false,
                        },
                      },
                      markers: {
                        size: 8,
                      },
                      plotOptions: {
                        bar: {
                          horizontal: true,
                        },
                      },
                    }
                  }
                  series={[{
                    name: 'Cycle',
                    data: cycleData,
                  }]}
                  width="100%"
                  height="500"
                  type="bar"
                />
              </ChartContainer>
            </Box>
            <Box>
              <Heading as="h2" mb={4} fontWeight="normal" fontSize="2xl">
                Public Transport
              </Heading>
              <ChartContainer>
                <Chart
                  options={
                    {
                      chart: {
                        animations: {
                          enabled: false,
                        },
                        zoom: {
                          enabled: false,
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      tooltip: {
                        enabled: true,
                        shared: false,
                        y: {
                          show: true,
                          formatter(val) {
                            return `${val.toFixed(0)}%`;
                          },
                        },
                      },
                      yaxis: {
                        labels: {
                          offsetX: 0,
                          offsetY: 3,
                          style: {
                            fontSize: '10px',
                          },
                        },
                      },
                      xaxis: {
                        title: {
                          text: '% Access to Jobs',
                        },
                        tickAmount: 4,
                        forceNiceScale: true,
                        labels: {
                          formatter(val) {
                            return `${val.toFixed(0)}%`;
                          },
                        },
                      },
                      legend: {
                        onItemClick: {
                          toggleDataSeries: true,
                        },
                      },
                      markers: {
                        size: 8,
                      },
                      plotOptions: {
                        bar: {
                          horizontal: true,
                        },
                      },
                    }
                  }
                  series={[{
                    name: 'Public Transport',
                    data: ptData,
                  }]}
                  width="100%"
                  height="500"
                  type="bar"
                />
              </ChartContainer>
            </Box>
            <Box>
              <Heading as="h2" mb={4} fontWeight="normal" fontSize="2xl">
                Drive
              </Heading>
              <ChartContainer>
                <Chart
                  options={
                    {
                      chart: {
                        animations: {
                          enabled: false,
                        },
                        zoom: {
                          enabled: false,
                        },
                      },
                      dataLabels: {
                        enabled: false,
                      },
                      tooltip: {
                        enabled: true,
                        shared: false,
                        y: {
                          show: true,
                          formatter(val) {
                            return `${val.toFixed(0)}%`;
                          },
                        },
                      },
                      yaxis: {
                        labels: {
                          offsetX: 0,
                          offsetY: 3,
                          style: {
                            fontSize: '10px',
                          },
                        },
                      },
                      xaxis: {
                        title: {
                          text: '% Access to Jobs',
                        },
                        tickAmount: 4,
                        forceNiceScale: true,
                        labels: {
                          formatter(val) {
                            return `${val.toFixed(0)}%`;
                          },
                        },
                      },
                      legend: {
                        onItemClick: {
                          toggleDataSeries: false,
                        },
                      },
                      markers: {
                        size: 8,
                      },
                      plotOptions: {
                        bar: {
                          horizontal: true,
                        },
                      },
                    }
                  }
                  series={[{
                    name: 'Drive',
                    data: driveData,
                  }]}
                  width="100%"
                  height="500"
                  type="bar"
                />
              </ChartContainer>
            </Box>
          </SimpleGrid>
        </Container>
      </MainLayout>
    );
  }

  // Not a region
  return (
    <MainLayout>
      <Container maxW="full">
        <EmptyState title="Not a Region" message="These reports only return data for regions." />
      </Container>
    </MainLayout>
  );
}
