import {
  Container,
  Heading,
  Text,
} from '@chakra-ui/react';
import { isEmpty } from 'lodash';
import Chart from 'react-apexcharts';
import MainLayout from '@src/components/layouts/main';
import { useReports } from '@src/modules/regional-reporting/context/reports';
import LoadingSpinner from '@src/components/shared/LoadingSpinner';
import ChartContainer from '@src/modules/onf/components/shared/ChartContainer';
import Pagination from '@src/modules/regional-reporting/components/Pagination';
import EmptyState from '@src/components/shared/EmptyState';
import InfoPane from '@src/modules/regional-reporting/components/InfoPane';
import { reportsList } from '@src/modules/regional-reporting/api/reports-list';

export default function ForecastMaintenanceSpend() {
  const { data: report, isLoading: isLoadingReport } = useReports('forecast-maintenance-spend');
  const { description, notification } = reportsList.find((x) => x.url === 'forecast-maintenance-spend');
  const regionName = report?.rcaName?.replace('Region', '');

  const series = report?.data?.sort((a, b) => a.roadCouncil.localeCompare(b.roadCouncil)).map((item) => ({
    roadCouncil: item.roadCouncil,
    value: item.budget,
    colour: item.councilColour,
  }));

  if (isLoadingReport) {
    return (
      <MainLayout>
        <Container maxW="full" display="flex" flexDir="column" minHeight="100%">
          <Pagination currentReportUrl="forecast-maintenance-spend" reportData={report} isLoading />
          <LoadingSpinner />
        </Container>
      </MainLayout>
    );
  }

  if (isEmpty(report?.data) && report?.rcaName !== null) {
    return (
      <MainLayout>
        <Container maxW="full" display="flex" flexDir="column" minHeight="100%">
          <Pagination currentReportUrl="forecast-maintenance-spend" reportData={report} isLoading={false} />
          <EmptyState title="No Data" message="Sorry there is no data available for selected region" />
        </Container>
      </MainLayout>
    );
  }

  if (!isEmpty(report?.data)) {
    return (
      <MainLayout>
        <Container maxW="full">
          <Pagination currentReportUrl="forecast-maintenance-spend" reportData={report} isLoading={false} />
          {description
              && (
                description
              )}
          {notification
              && (
                <InfoPane>
                  <Text>{notification}</Text>
                </InfoPane>
              )}
          <Heading as="h2" mb={4} fontWeight="normal" fontSize="2xl">
            Forecast road maintenance spend by authority
          </Heading>
          <Text mb={4}>
            1 July 2021 to 30 June 2031
          </Text>
          <ChartContainer>
            <Chart
              options={
                {
                  chart: {
                    stacked: true,
                    stackType: '100%',
                  },
                  xaxis: {
                    categories: [''],
                    axisBorder: {
                      show: false,
                    },
                    axisTicks: {
                      show: false,
                    },
                  },
                  yaxis: [
                    {
                      show: false,
                      forceNiceScale: true,
                      tickAmount: 8,
                      reversed: false,
                      labels: {
                        formatter(val) {
                          return `${val ? val.toFixed(0) : val}%`;
                        },
                      },
                      axisBorder: {
                        show: false,
                      },
                      axisTicks: {
                        show: false,
                      },
                    },
                  ],
                  grid: {
                    show: false,
                    padding: {
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: -300,
                    },
                  },
                  colors: series.map((x) => x.colour),
                  labels: series.map((x) => x.roadCouncil),
                  dataLabels: {
                    enabled: true,
                    style: {
                      fontSize: '14px',
                      fontFamily: 'Helvetica, Arial, sans-serif',
                      fontWeight: 'bold',
                      colors: series.map((x) => x.colour),
                    },
                    textAnchor: 'start',
                    offsetX: 260,
                    dropShadow: {
                      enabled: false,
                    },
                    formatter: (val, opt) => `${series[opt.seriesIndex].roadCouncil} ${new Intl.NumberFormat('en-NZ', {
                      notation: 'compact',
                      minimumFractionDigits: 1,
                      style: 'currency',
                      currency: 'NZD',
                    }).format(series[opt.seriesIndex].value)}` // Display original value
                    ,
                  },
                  tooltip: {
                    enabled: true,
                    y: {
                      formatter: () => '',
                      title: {
                        formatter: (seriesName) => `${seriesName} `,
                      },
                    },
                  },
                  legend: {
                    showForSingleSeries: true,
                    position: 'left',
                    offsetY: 30,
                  },
                  stroke: {
                    show: true,
                    colors: ['white'],
                    width: 2,
                  },
                  plotOptions: {
                    bar: {
                      columnWidth: '25%',
                      dataLabels: {
                        position: 'center',
                      },
                    },
                  },
                  responsive: [
                    {
                      breakpoint: 1440,
                      options: {
                        plotOptions: {
                          bar: {
                            columnWidth: '30%',
                          },
                        },
                        dataLabels: {
                          offsetX: 170,
                        },
                      },
                    },
                    {
                      breakpoint: 1268,
                      options: {
                        plotOptions: {
                          bar: {
                            columnWidth: '30%',
                          },
                        },
                        dataLabels: {
                          offsetX: 180,
                        },
                      },
                    },
                    {
                      breakpoint: 1024,
                      options: {
                        plotOptions: {
                          bar: {
                            columnWidth: '20%',
                          },
                        },
                        dataLabels: {
                          offsetX: 100,
                        },
                      },
                    }],
                }
              }
              series={series.map((x) => ({ data: [x.value], name: x.roadCouncil }))}
              type="bar"
              width="100%"
              height="600px"
            />
            <Text textAlign="center">
              Total forecast in
              {' '}
              {regionName}
              :
              {' '}
              {new Intl.NumberFormat('en-NZ', {
                notation: 'compact',
                minimumFractionDigits: 1,
                style: 'currency',
                currency: 'NZD',
              }).format(series.map((x) => x.value).reduce((a, b) => a + b, 0))}
            </Text>
          </ChartContainer>
        </Container>
      </MainLayout>
    );
  }

  // Not a region
  return (
    <MainLayout>
      <Container maxW="full">
        <EmptyState title="Not a Region" message="These reports only return data for regions." />
      </Container>
    </MainLayout>
  );
}
